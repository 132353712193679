import React from 'react';
import {Box, HStack, Badge, Container, Heading, Text, Image, Button, Show} from '@chakra-ui/react';
import logo from '../assets/img/logo.svg';
import {FormattedMessage} from "react-intl";
import reviews from '../assets/img/reviews.jpg';
import {ArrowForwardIcon} from "@chakra-ui/icons";

export const PageHeader = () => {
    const scrollToBottom = () => {
        window.scroll({
            top: document.body.offsetHeight, left: 0, behavior: 'smooth',
        });
    };

    return (<Box className="header-container" w="100%" pt={[5, 9]} pb={[5, 5, 20]}>
            <Container maxW="container.lg">
                <HStack flexDirection={{base: 'column', md: 'row'}}>
                    <Box w={{sm: '100%', md: '50%'}}>
                        <HStack mb={3} justifyContent={''}>
                            <Box w="100%">
                                <img width="120" src={logo} alt="logo"/>
                            </Box>
                            <Badge fontSize="1em"><FormattedMessage id="header.newBadge"/></Badge>
                        </HStack>

                        <Heading mb={[1, 5]} fontSize={[22,30]}><FormattedMessage id="header.headline"/></Heading>
                        <Text fontSize={[16,20]}><FormattedMessage id="header.subline"/></Text>
                    </Box>

                    <Show below={'md'}>
                        <Box w={'100%'} pt={2}>
                            <Button w={'100%'} size={'md'} color={'#ec695d'} onClick={scrollToBottom}>
                                <ArrowForwardIcon mr={1}/>
                                <FormattedMessage id="button.jumpToForm"/>
                            </Button>
                        </Box>
                    </Show>

                    <Box pl={{sm: 0, md: 10}}
                         pt={{base: 5, md: 0}}
                         mt={{sm: 10, md: 0}}
                         m={{base: '0 !important'}}
                         w={{sm: '100%', md: '50%'}}>
                        <Image className="m4" borderRadius={'0.375rem'} width="100%" src={reviews} alt="reviews"/>
                    </Box>
                </HStack>
            </Container>
        </Box>)
}
